import React, { useState, useEffect, useMemo } from 'react';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import bag from "../../Assets/Images/finance/bag.jpg";
import svgTry from "../../Assets/Images/finance/currency/try.svg";
import svgUsd from "../../Assets/Images/finance/currency/usd.svg";
import svgEur from "../../Assets/Images/finance/currency/eur.svg";
import svgGbp from "../../Assets/Images/finance/currency/gbp.svg";
import svgDefault from "../../Assets/Images/finance/currency/try.svg";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

function CompDtOrder() {
    const { t } = useTranslation();
    const [componentId] = useState('compDtOrder');
    const [data, setData] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [sort, setSort] = useState('createDate,DESC');
    const [paginationRowsPerPageOptions] = useState([1, 5, 10, 25, 50, 100]);
    const [totalRows, setTotalRows] = useState(0);


    React.useMemo(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/trader/my-sales-order/list?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sort=' + sort)
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data?.payload);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [pageNumber, pageSize, sort]);


    const [deleteModal, setDeleteModal] = useState(false);
    const [recordId, setRecordId] = useState(false);

    const onGoToItemDetails = props => {
        //history.push('/product/' + props);
        //dispatch(mpMyCashDeskListApi(props));
    };

    const onBtnDeleteItem = props => {
        let message = 'Prohibited action!';
        toast.error(message, { autoClose: 1000 });
        console.log(props);
        setRecordId(props);
        //setDeleteModal(true);
    };

    const handleDeleteTask = () => {
        if (recordId) {
            //dispatch(mpMyCashDeskDeleteByIdApi({ cashDeskId: recordId }));
            //setDeleteModal(false);
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
        }
    };


    useEffect(() => {
        //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
    }, []);


    const handlePageChange = page => {
        setPageNumber(page - 1);
        //dispatch(mpMyCashDeskListApi(page - 1, pageSize, sort));
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageSize(newPerPage);
        //dispatch(mpMyCashDeskListApi(0, newPerPage, sort));
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>{t('Erp.Invoice.SalesInvoice.CompSalesInvoiceDataTable.Datatable.Th.CreationDate')}</span>,
            selector: row => moment(row?.latestUpdate).format('DD.MM.YYYY, HH:mm'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Bpm.Erp.Invoice.SalesInvoiceCreate.Datatable.Th.SubTotal')}</span>,
            selector: row => row?.total + ' ' + row?.items?.[0]?.cashDesk?.account?.currency?.symbol,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Bpm.Erp.Invoice.SalesInvoiceCreate.Label.CollectionStatus')}</span>,
            selector: row => row?.paymentStatus?.label,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Datatable.Th.Actions')}</span>,
            selector: row =>
                <div className="hstack gap-3">
                    <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => { onBtnDeleteItem(row.id) }}>
                        <i className="ri-bank-card-fill align-bottom" />
                    </button>
                    <button className="btn btn-sm btn-soft-info edit-list" onClick={() => { onGoToItemDetails(row.id) }}>
                        <i className="ri-file-text-line align-bottom" />
                    </button>
                </div>,
            sortable: false
        },
    ];

    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(data?.content);
            setPending(false);
            setTotalRows(data?.totalElements);
        }, 0);
        return () => clearTimeout(timeout);
    }, [data?.content, data?.totalElements]);


    return (
        <React.Fragment>
            <ToastContainer />
            <DataTable
                id={componentId}
                columns={columns}
                data={rows}
                pagination={true}
                paginationServer
                onPageChange={page => setPageNumber(page)}
                paginationPerPage={pageSize}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={pending}
                noDataComponent={t('Datatable.Label.NoData')}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationComponentOptions={{
                    rowsPerPageText: t('Datatable.Label.RowsPerPage'),
                    rangeSeparatorText: t('Datatable.Label.OutOf'),
                }}
            />
        </React.Fragment>
    );
}
export default CompDtOrder;