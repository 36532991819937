import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Spinner } from 'reactstrap';
import moment from 'moment';
import classnames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';


// Import Images
import Img3 from "../../Assets/Images/nft/img-03.jpg";
import Img5 from "../../Assets/Images/nft/img-05.jpg";
import Img6 from "../../Assets/Images/nft/img-06.jpg";
import ImgGif3 from "../../Assets/Images/nft/gif/img-3.gif";

export default function ProductDetails(props) {


    const { t } = useTranslation();

    let id = props.itemId;

    const [productName, setProductName] = useState('');
    const [productImage, setProductImage] = useState('');
    const [published, setPublished] = useState('');
    const [seller, setSeller] = useState('');
    const [category, setCategory] = useState('');
    const [productListPrice, setProductListPrice] = useState('');
    const [productListPriceCurrency, setProductListPriceCurrency] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const [deliveryTimeType, setDeliveryTimeType] = useState('');
    const [explanation, setExplanation] = useState('');

    useEffect(() => {
        let ApiGateway = process.env.REACT_APP_API_URL;
        axios.get(ApiGateway + '/market-place/bazaar/item/' + id)
            .then(response => {
                setProductName(response.data?.payload?.product?.name);

                let productImage = response.data?.payload?.productImageFile?.cdnStorage.url;
                let cdnStorageSnapshots = response.data?.payload?.productImageFile?.cdnStorage?.cdnStorageSnapshots;
                if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots?.length > 0) {
                    for (let x = 0; x < cdnStorageSnapshots?.length; x++) {
                        if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots[x]?.fileSnapshotType?.value === 'IMAGE_500X500') {
                            productImage = cdnStorageSnapshots[x]?.url;
                        }
                    }
                }
                setProductImage(productImage);

                setSeller(response.data?.payload?.crm?.title);
                let date = response.data?.payload?.cashDesk?.account?.creationDate;
                const formattedDate = moment(date).format('DD.MM.YYYY, HH:mm');
                setPublished(formattedDate);
                setCategory(t('language') === "en" ? response.data?.payload?.category?.labelEn : response.data?.payload?.category?.labelTr);
                setProductListPrice(response.data?.payload?.productListPrice);
                setDeliveryTime(response.data?.payload?.deliveryTime);
                setDeliveryTimeType(response.data?.payload?.deliveryTimeType?.label);
                setProductListPriceCurrency(response.data?.payload?.cashDesk?.account?.currency?.symbol);
                setExplanation(response.data?.payload?.explanation);
            })
            .catch(error => {
                console.error(error);
            });
    }, [t, id]);


    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const [data, setData] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);

    const [logged, setLogged] = useState(false);

    const onAddItemThenGoToBasket = itemId => {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            const requestBody = {
                sourceType: 'MARKET_PLACE',
                id: itemId,
                amount: 1
            };
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/basket/basket/item/add', requestBody)
                .then(response => {
                    setData(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            setApiCalled(true);
            toast.warning(message, { autoClose: 200 });
        } else {
            setApiCalled(true);
        }
    };

    useEffect(() => {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            setLogged(true);
        } else {
            setLogged(false);
        }

        if (data?.isSucceed === true) {
            let message = t('Api.Default.Service.Response.Succeded');
            toast.success(message, { autoClose: 1000 });
            setTimeout(() => {
                let redirectUrl = '/basket';
                window.location.href = redirectUrl;
            }, 1000);
        } else {
            data?.messages?.forEach(
                item => toast.error(item.message, { autoClose: 5000 })
            )
        }
    }, [t, data]);

    return (
        <React.Fragment>
            <ToastContainer />
            <Row className="g-4">
                <Col lg={4}>
                    <div className="sticky-side-div">
                        <Card className="ribbon-box border shadow-none right">
                            <div className="ribbon-two ribbon-two-danger d-none"><span><i className="ri-fire-fill align-bottom"></i> Hot</span></div>
                            {
                                <img
                                    data-dz-thumbnail=""
                                    height={500}
                                    width={500}
                                    className={'img-fluid rounded'}
                                    alt={productName}
                                    title={productName}
                                    src={productImage}
                                />
                            }
                            <div className="position-absolute bottom-0 p-3 d-none">
                                <div className="position-absolute top-0 end-0 start-0 bottom-0 bg-white opacity-25"></div>
                                <Row className="justify-content-center">
                                    <Col className="col-3">
                                        <img src={Img5} alt="" className="img-fluid rounded" />
                                    </Col>
                                    <Col className="col-3">
                                        <img src={Img3} alt="" className="img-fluid rounded" />
                                    </Col>
                                    <Col className="col-3">
                                        <img src={ImgGif3} alt="" className="img-fluid rounded h-100 object-cover" />
                                    </Col>
                                    <Col className="col-3">
                                        <img src={Img6} alt="" className="img-fluid rounded" />
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <div className="hstack gap-2 mt-2">
                            <Button disabled={!logged} onClick={e => onAddItemThenGoToBasket(id)} className={apiCalled === true ? 'btn btn-dark border-0 border w-100 disabled' : 'btn btn-dark border-0 border w-100'}>
                                <Spinner className={apiCalled === true ? '' : 'd-none'}
                                    size="sm"
                                    color="light"
                                >
                                </Spinner>
                                <>
                                    {!logged ? <span className={apiCalled !== true ? '' : 'd-none'}>{t('Login.Button.Login') + ' / ' + t('Product.Common.Label.Button.AddToBasket')}</span>
                                        : <span className={apiCalled !== true ? '' : 'd-none'}>{t('Product.Common.Label.Button.AddToBasket')}</span>}
                                </>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={8}>
                    <div>
                        <UncontrolledDropdown className="float-end d-none">
                            <DropdownToggle tag="a" className="btn btn-ghost-primary btn-icon" role="button">
                                <i className="ri-more-fill align-middle fs-16"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#" className="view-item-btn"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
                                <DropdownItem href="#" className="edit-item-btn"><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</DropdownItem>
                                <DropdownItem href="#" className="remove-item-btn"><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <span className="badge badge-soft-info mb-3 fs-12 d-none"><i className="ri-eye-line me-1 align-bottom"></i> 8,634 people views this</span>
                        <h4 className="fw-bold">{productName}</h4>
                        <div className="hstack gap-3 flex-wrap">
                            <div className="text-muted">{t('Mp.Bazaar.Details.Label.Category')} : <Link to="#" className="text-body fw-medium">{category}</Link></div>
                            <div className="vr"></div>
                            <div className="text-muted">{t('Mp.Bazaar.Details.Label.Seller')} : <span className="text-body fw-medium">{seller}</span></div>
                            <div className="vr"></div>
                            <div className="text-muted">{t('Mp.Bazaar.Details.Label.Published')} : <span className="text-body fw-medium">{published}</span></div>
                        </div>

                        <Row className="mt-4">
                            <Col lg={6} md={12} sm={12} >
                                <div className="p-2 border border-dashed rounded text-center">
                                    <div>
                                        <p className="text-muted fw-medium mb-1">{t('Mp.Bazaar.Details.Label.ProductListPrice')}  :</p>
                                        <h5 className="fs-2 mb-0"> {productListPrice} {' '} {productListPriceCurrency}</h5>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <div className="p-2 border border-dashed rounded text-center">
                                    <div>
                                        <p className="text-muted fw-medium mb-1">{t('Mp.Bazaar.Details.Label.DeliveryTime')}</p>
                                        <h5 className="fs-2 mb-0">{deliveryTime} {' '} {deliveryTimeType}</h5>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                        <div className="product-content mt-2">

                            <Nav tabs className="nav-tabs-custom nav-success">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        {t('Mp.Bazaar.Details.Tabs.Label.Details')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent
                                activeTab={customActiveTab}
                                className="border border-top-0 p-4"
                                id="nav-tabContent"
                            >
                                <TabPane
                                    id="nav-detail"
                                    tabId="1"
                                >
                                    <div>
                                        <h5 className="font-size-16 mb-3">{t('Mp.Bazaar.Details.Label.Explanation')}</h5>
                                        <p>{explanation}</p>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}