import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';
import axios from 'axios';

let items = [
    /*
    {
        src: 'https://picsum.photos/id/123/1200/400',
        altText: 'Slide 1',
        caption: 'Slide 1',
        key: 1,
    },
    {
        src: 'https://picsum.photos/id/456/1200/400',
        altText: 'Slide 2',
        caption: 'Slide 2',
        key: 2,
    },
    {
        src: 'https://picsum.photos/id/678/1200/400',
        altText: 'Slide 3',
        caption: 'Slide 3',
        key: 3,
    },
    */
];

function CategorySlider(args) {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const [data, setData] = useState([]);

    React.useMemo(() => {
        axios.defaults.headers.common["X-User-Language"] = 'TR';
        let ApiGateway = process.env.REACT_APP_API_URL;
        axios.get(ApiGateway + '/market-place/general/slider/find-only-active-records/list')
            .then(response => {
                if (response.data?.isSucceed === true) {
                    setData(response.data?.payload);
                    items = [];
                    response.data?.payload?.forEach((item) => {
                        let caption = t('language') === 'tr' ? item?.captionTr : item?.captionEn;
                        let altText = t('language') === 'tr' ? item?.altTextTr : item?.altTextEn;
                        let x = {
                            src: 'https://picsum.photos/id/123/1200/400',
                            caption: caption,
                            altText: altText,
                            key: item?.id,
                            linkHref: item?.linkHref,
                            linkTargetType: item?.linkTargetType?.value,
                        };
                        items.push(x);
                        console.log(items);
                    });
                }

            })
            .catch(error => {
                console.error(error);
            });
    }, [t]);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                {
                    (item?.linkHref !== null && item?.linkHref !== '') ?
                        <a href={item?.linkHref} target={item?.linkTargetType}>
                            <img src={item.src} alt={item.altText} width={1500} height={365} />
                        </a>
                        :
                        <img src={item.src} alt={item.altText} width={1500} height={365} />
                }
                <CarouselCaption
                    captionText={item.caption}
                    captionHeader={item.caption}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            {...args}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
}

export default CategorySlider;