import './App.css';
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import CategoryProductListPage from './Pages/Category/CategoryProductListPage';
import ProductDetails from './Pages/Product/Details';
import Login from './Pages/Member/Login';
import Register from './Pages/Member/Register';
import MyAccount from './Pages/Member/MyAccount';
import MyMpCbiProcessList from './Pages/Member/MyMpCbiProcessList';
import CreateCbiProcessApplication from './Pages/Member/Process/CBI/CreateCbiProcessApplication';
import CreateCbiProcessApplicationReturn from './Pages/Member/Process/CBI/CreateCbiProcessApplicationReturn';
import CreateCbiProcessApplicationApproval from './Pages/Member/Process/CBI/CreateCbiProcessApplicationApproval';
import Basket from './Pages/Basket';
import Other from './Pages/Other';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import ProtectionOfPersonalData from './Pages/ProtectionOfPersonalData';
import FrequentlyAskedQuestions from './Pages/FrequentlyAskedQuestions';
import BeingASeller from './Pages/BeingASeller';
import ChangedLanguage from './Pages/ChangedLanguage';
import Session from './Components/Session';
import Callback from './Pages/Checkout/Callback';


const publicRoutes = [
  // Authentication Page
  { path: "/", component: Home },
  { path: "/changed-language", component: ChangedLanguage },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/other", component: Other },
  { path: "/about-us", component: AboutUs },
  { path: "/contact", component: Contact },
  { path: "/frequently-asked-questions", component: FrequentlyAskedQuestions },
  { path: "/being-a-seller", component: BeingASeller },
  { path: "/protection-of-personal-data", component: ProtectionOfPersonalData },

  { path: "/category/:id/product-list", component: CategoryProductListPage },
  { path: "/product/:id/details", component: ProductDetails },
  { path: "/protection-of-personal-data", component: ProtectionOfPersonalData },

  { path: "/checkout/callback/:id?", component: Callback },
];

const authProtectedRoutes = [

  { path: "/my-account/:id?", component: MyAccount },
  { path: "/basket", component: Basket },
  { path: "/my-cbi-process-list", component: MyMpCbiProcessList },
  { path: "/create-cbi-process/:taskId/application", component: CreateCbiProcessApplication },
  { path: "/create-cbi-process/:taskId/application-return", component: CreateCbiProcessApplicationReturn },
  { path: "/create-cbi-process/:taskId/application-approval", component: CreateCbiProcessApplicationApproval },

];


function App() {
  return (
    <div className="App">
      <Routes>
        <React.Fragment>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<><route.component /></>}
              key={idx}
              exact={true}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<><Session><route.component /></Session></>}
              key={idx}
              exact={true}
            />
          ))}
        </React.Fragment>
      </Routes>
    </div>
  );
}

export default App;
