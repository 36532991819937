import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Toast, Button, Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import 'ckeditor5/ckeditor5.css';
import axios from 'axios';
import { useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import defaultProfile from "../../Assets/Images/profile/defaultProfile.jpg";
import moment from 'moment';

function CompDecisionDataTable({ taskId }) {
    const { t } = useTranslation();
    const [componentId] = useState('decisionDataTable');
    const [data, setData] = useState([]);
    const [dataClaimTask, setDataClaimTask] = useState(null);
    const [dataReassignBackToPool, setDataReassignBackToPool] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [sort, setSort] = useState('createDate,DESC');
    const [paginationRowsPerPageOptions] = useState([1, 5, 10, 25, 50, 100]);
    const [totalRows, setTotalRows] = useState(0);

    const [recordId, setRecordId] = useState(false);

    const loadData = React.useCallback(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/bpd/decision/' + taskId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sort=' + sort)
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data?.payload);
                        setTotalRows(response.data?.payload?.totalElements);
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [pageNumber, pageSize, sort, taskId]);

    const onGoToItemDetails = props => {
        let redirectUrl = '';
        switch (props.acronym) {
            case 'MP_CBI':
                if (props.taskName === 'Başvuru') {
                    redirectUrl = '';
                }
                break;
            default:
                break;
        }
        window.location.href = redirectUrl;
    };

    const onBtnDeleteItem = props => {
        let message = 'Prohibited action!';
        toast.error(message, { autoClose: 1000 });
        console.log(props);
        setRecordId(props);
        //setDeleteModal(true);
    };

    const onBtnClaimTask = props => {
        let taskId = props;
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.put(ApiGateway + '/bpm/task/user/task/claim/' + taskId)
                .then(response => {
                    setData(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            toast.warning(message, { autoClose: 200 });
        }
    };

    const onBtnReassignBackToPool = props => {
        let taskId = props;
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.put(ApiGateway + '/bpm/task/' + taskId + '/reassignBackToPool')
                .then(response => {
                    setDataReassignBackToPool(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            toast.warning(message, { autoClose: 200 });
        }
    };


    const handleDeleteTask = () => {
        if (recordId) {
            //dispatch(mpMyCashDeskDeleteByIdApi({ cashDeskId: recordId }));
            //setDeleteModal(false);
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
        }
    };


    useEffect(() => {
        loadData();
    }, [loadData, pageSize, pageNumber]);

    useEffect(() => {
        if (dataClaimTask !== undefined && dataClaimTask !== null) {
            let message = t('Product.Common.Label.Data.Notification.Loaded');
            toast.success(message, { autoClose: 1000 });
            loadData();
        }
    }, [t, loadData, dataClaimTask]);

    useEffect(() => {
        if (dataReassignBackToPool !== undefined && dataReassignBackToPool !== null) {
            let message = t('Product.Common.Label.Data.Notification.Loaded');
            toast.success(message, { autoClose: 1000 });
            loadData();
        }
    }, [t, loadData, dataReassignBackToPool]);


    const handlePageChange = async (page) => {
        setPageNumber(page - 1);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageSize(newPerPage);
        setPageNumber(page - 1);
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Common.Label.Bpm.DecisionTable.Datatable.Th.User')}</span>,
            selector: row =>
                <div className="d-flex align-items-center mt-2">
                    <div className="flex-shrink-0 mb-2">
                        <img src={defaultProfile} width={35} height={35} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1 ms-2">
                        <h6 className="mb-1">{/*<Link to={'/crm/' + row.id + '/details'} className="text-body">{row.title}</Link>*/}{row.userDisplayName}</h6>
                        <p className="text-muted mb-2"></p>
                    </div>
                    <div className="flex-shrink-0">
                    </div>
                </div>
            ,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Common.Label.Bpm.DecisionTable.Datatable.Th.Explanation')}</span>,
            selector: row => <span dangerouslySetInnerHTML={{ __html: row.explanation }} />,

            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Common.Label.Bpm.DecisionTable.Datatable.Th.Decision')}</span>,
            selector: row => row.decision?.label,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Common.Label.Bpm.DecisionTable.Datatable.Th.DecisionDate')}</span>,
            selector: row => row.endDate != null ? moment(row.endDate).format('DD.MM.YYYY HH:mm') : '-',
            sortable: false
        },
    ];

    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(data?.content);
            setPending(false);
            setTotalRows(data?.totalElements);
        }, 0);
        return () => clearTimeout(timeout);
    }, [data?.content, data?.totalElements]);

    return (
        <React.Fragment>
            <ToastContainer />
            <DataTable
                id={componentId}
                columns={columns}
                data={rows}
                pagination={true}
                paginationServer
                onPageChange={page => setPageNumber(page)}
                paginationPerPage={pageSize}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={pending}
                noDataComponent={t('Datatable.Label.NoData')}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationComponentOptions={{
                    rowsPerPageText: t('Datatable.Label.RowsPerPage'),
                    rangeSeparatorText: t('Datatable.Label.OutOf'),
                }}
            />
        </React.Fragment>
    );
}
export default CompDecisionDataTable;