import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Session = ({ children }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('');
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            setLogged(true);
        } else {
            const timeout = setTimeout(() => {
                let redirectUrl = '/login';
                window.location.href = redirectUrl;
                navigate(redirectUrl);
            }, 200);
        }
    }, [navigate]);


    return (
        <React.Fragment>
            {children && children}
        </React.Fragment >
    )
};

export default Session;