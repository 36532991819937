import React, { useState, useEffect, useMemo } from 'react';
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const CompFileUploader = ({ onChange, datastore, isDeleteButtonDisabled }) => {
    const { t } = useTranslation();
    const [componentId] = useState('fileUploader');

    const [apiCalled, setApiCalled] = useState(false);

    const [data, setData] = useState(null);

    const [file, setFile] = useState(null);
    const [invoiceFileId, setInvoiceFileId] = useState('');
    const [selectedFiles, setselectedFiles] = useState([]);

    function handleInvoiceFileChange(event) {
        setFile(event.target.files[0]);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }


    const handleAcceptedFiles = React.useCallback((files) => {
        files.forEach(file => {
            if (file !== null) {
                console.log(file);
                Object.assign(file, {
                    preview: file.preview,
                    formattedSize: formatBytes(file.size),
                });
            }
        }
        );
        setselectedFiles(files);
    }, []);

    const uploadFileToService = React.useCallback((callbackData) => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/file/file/upload-to-cdn-storage', callbackData)
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data?.payload);
                        let message = t('Api.Default.Service.Response.Succeded');
                        toast.success(message, { autoClose: 1000 });
                    } else {
                        response.data?.messages?.forEach((item) => {
                            let message = item.message;
                            toast.error(message, { autoClose: 5000 });
                        });
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [t]);

    useEffect(() => {
        console.log(data);
        if (data !== null) {
            onChange(data?.id);
        }
    }, [data, onChange]);

    const getFileToService = React.useCallback((serviceFileId) => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/file/file/' + serviceFileId)
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data?.payload);
                        let message = t('Api.Default.Service.Response.Succeded');
                        toast.success(message, { autoClose: 1000 });
                    } else {
                        response.data?.messages?.forEach((item) => {
                            let message = item.message;
                            toast.error(message, { autoClose: 5000 });
                        });
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [t]);

    function deleteFileById() {
        setApiCalled(true);
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.delete(ApiGateway + '/file/file/' + data?.id + '/delete')
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data);
                        let message = t('Api.Default.Service.Response.Succeded');
                        toast.success(message, { autoClose: 1000 });
                    } else {
                        response.data?.messages?.forEach((item) => {
                            let message = item.message;
                            toast.error(message, { autoClose: 5000 });
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        if (file !== undefined && file !== null) {
            setApiCalled(true);
            if (invoiceFileId === '') {
                let message = t('Product.Common.Label.FileUploading');
                //toast.warning(message, { autoClose: 10000 });
            }
            const formData = new FormData();
            formData.append("file", file);
            //dispatch(uploadFileApi(formData));
            uploadFileToService(formData);
            let in_files = [];
            in_files.push(file);
            handleAcceptedFiles(in_files);
        }
    }, [t, file, handleAcceptedFiles, invoiceFileId, uploadFileToService, datastore]);


    useEffect(() => {
        if (datastore !== undefined && datastore !== null && datastore !== '') {
            getFileToService(datastore);
        }
    }, [t, datastore, getFileToService]);

    return (
        <React.Fragment>
            <ToastContainer />
            <div>
                {(data !== null) ? (
                    <>
                        <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={data?.fileName}
                            src={data?.cdnStorage?.url}
                        //onClick={zoomInvoiceFileById}
                        />
                        <br /> <br />
                        <Link className={isDeleteButtonDisabled === true ? "d-none" : "text-muted font-weight-bold"}
                            to="#" onClick={deleteFileById}
                        >
                            {t('Product.Common.Label.RemoveFile')}
                        </Link>
                    </>


                ) : (
                    <Input className="form-control" type="file" id="formFile" onChange={handleInvoiceFileChange} />
                )}
            </div>
        </React.Fragment>
    );
}
export default CompFileUploader;