import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StandartPage from '../../Layouts/Shared/_StandartPage';
import { Row, Col, Button } from 'reactstrap';
import MpCbiProcessList from '../../Components/MyProcess/MpCbiProcessList'
import axios from 'axios';

export default function MyProcess() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [apiCalled, setApiCalled] = useState(false);
    const [MPCBIBasvuruOnayGrubu, setMPCBIBasvuruOnayGrubu] = useState(false);

    const callCreateBpdService = React.useCallback(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            let bpdName = 'MP_CBI';
            axios.post(ApiGateway + '/bpm/integration/' + bpdName + '/start-process-instance')
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        let taskId = response.data?.payload?.id;
                        let redirectUrl = '/create-cbi-process/' + taskId + '/application'
                        //window.location.href = redirectUrl;
                        navigate(redirectUrl);
                    } else {
                        setApiCalled(false);
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [navigate]);

    const callPopUp = () => {
        callCreateBpdService();
    }


    useEffect(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            let groups = JSON.parse(currentSession)?.session?.groups;
            Object.values(groups).forEach(item => {
                if (item?.name === 'MP CBI Basvuru Onay Grubu') {
                    setMPCBIBasvuruOnayGrubu(true);
                }
            });
        }
    }, [t]);

    return (
        <StandartPage title={MPCBIBasvuruOnayGrubu === true ? t('Product.Common.Label.Bpm.ActiveProcess') : t('Mp.Cbi.MyProcess.List.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <div className='d-none'><span className='f5'>{t('Henüz satışta ürününüz bulunmuyor. Ürünlerinizi ekleyin Yuhdeve.com ile farkı yaşayın!')}</span></div>
                <Row className={"mt-3 mb-3"}>
                    <Col className={"col-md-2"}>
                        <div className="hstack gap-2 justify-content-end">
                            <Button disabled={MPCBIBasvuruOnayGrubu} hidden={MPCBIBasvuruOnayGrubu} onClick={e => callPopUp(e)} className={'btn btn-dark border-0 border w-100'}>
                                <i className="ri-add-line align-bottom me-1"></i>
                                <span className={apiCalled !== true ? '' : 'd-none'}>{t('Mp.Cbi.MyProcess.List.Button.Label.Create')}</span>
                            </Button>
                        </div>
                    </Col>
                    <Col className={"col-md-10"}>

                    </Col>
                </Row>
                <Row>
                    <Col className={"col-md-12"}>
                        <MpCbiProcessList />
                    </Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}