import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useParams, } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Callback = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    let id = params.id;

    const [data, setData] = useState(null);

    useEffect(() => {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let redirectUrl = '/my-account/order';
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/finance/payment/check/' + id, null)
                .then(response => {
                    if (response?.data?.isSucceed && response?.data?.payload?.succeeded) {
                        let message = t('Api.Default.Service.Response.Succeded');
                        toast.success(message, { autoClose: 2000 });
                        const timeout = setTimeout(() => {
                            window.location.href = redirectUrl;
                        }, 3000);
                    } else {
                        response?.data?.messages?.forEach(
                            item => toast.error(item.message, { autoClose: 2000 })
                        );
                        const timeout = setTimeout(() => {
                            window.location.href = redirectUrl;
                        }, 3000);
                    }
                })
                .catch(error => {
                    console.error(error);
                    window.location.href = redirectUrl;
                });

            const timeout = setTimeout(() => {
                let callbackUrl = '/checkout/callback/' + id;
                window.location.href = callbackUrl;
            }, 5000);
        }
    }, [t, navigate, id]);



    return (
        <React.Fragment>
            <ToastContainer />
            <h3>{t('Product.Common.Label.Bpm.Button.PleaseWait')}</h3>
        </React.Fragment >
    )
};

export default Callback;