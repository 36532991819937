import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const LanguageBar = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('');

    let trBox = '';
    let enBox = '';
    if (t('language') === 'tr') {
        trBox = 'm-1 btn-sm btn-dark w-100 disabled mr-4';
        enBox = 'm-1 btn-sm btn-dark w-100 mr-4';
    } else {
        trBox = 'm-1 btn-sm btn-dark w-100 mr-4';
        enBox = 'm-1 btn-sm btn-dark w-100 disabled mr-4';
    }


    useEffect(() => {
        if (language !== null && language !== '') {
            localStorage.setItem("I18N_LANGUAGE", language);
            let message = 'Dil Değiştildi  ---> '.concat('[').concat(language).concat(']');
            toast.success(message, { autoClose: 200 });
            const timeout = setTimeout(() => {
                let redirectUrl = '/changed-language';
                //navigate(link);
                window.location.href = redirectUrl;
            }, 200);
        }
    }, [language, navigate]);

    return (
        <React.Fragment>
            <ToastContainer />
            <div className='text-right' style={{textAlign: 'right'}}>
            <div className='btn-group col-md-5'>
                <Button className={trBox} onClick={() => setLanguage('tr')}>Türkçe</Button>
                <Button className={enBox} onClick={() => setLanguage('en')}>English</Button>
            </div>
            </div>
        </React.Fragment >
    )
};

export default LanguageBar;