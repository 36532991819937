import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import StandartPage from '../../../../Layouts/Shared/_StandartPage';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Toast, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import 'ckeditor5/ckeditor5.css';
import CompFileUploader from '../../../../Components/Common/CompFileUploader';
import CompMpCategoryDropdown from '../../../../Components/Common/CompMpCategoryDropdown';
import CompMpDeliveryTimeTypeDropdown from '../../../../Components/Common/CompMpDeliveryTimeTypeDropdown';
import CompMpMyCashDeskDropdown from '../../../../Components/Common/CompMpMyCashDeskDropdown';
import CompDecisionDataTable from '../../../../Components/Common/CompDecisionDataTable';
import CompCrmDetailsTab from '../../../../Pages/Member/Process/CBI/CompCrmDetailsTab';
import axios from 'axios';
import { useParams } from "react-router-dom";

export default function CreateCbiProcessApplicationReturn() {

    const { t } = useTranslation();
    const [componentId] = useState('CreateCbiProcessApplicationReturn');
    const [seed, setSeed] = useState(0);
    const params = useParams();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [processId, setProcessId] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [dataBpmTask, setDataBpmTask] = useState(null);

    const [dataSave, setDataSave] = useState(null);
    const [dataSendToApproval, setDataSendToApproval] = useState(null);
    const [dataReturn, setDataReturn] = useState(null);
    const [dataApproval, setDataApproval] = useState(null);


    const [apiCalled, setApiCalled] = useState(false);
    const [apiCalledBy, setApiCalledBy] = useState('');
    const [dataLocked, setDataLocked] = useState(componentId === 'CreateCbiProcessApplicationApproval' ? true : false);

    const [selectedItemMyCashDesk, setSelectedItemMyCashDesk] = useState('-1');
    const [selectedItemCategory, setSelectedItemCategory] = useState('-1');
    const [productName, setProductName] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const [selectedItemDeliveryTimeType, setSelectedItemDeliveryTimeType] = useState('-1');
    const [disableDeliveryTime, setDisableDeliveryTime] = useState(true);
    const [productImageFileId, setProductImageFileId] = useState('');
    const [productListPrice, setProductListPrice] = useState(0);
    const [commissionRate, setCommissionRate] = useState(30);
    const [commissionRateText, setCommissionRateText] = useState('%' + commissionRate);
    const [netAmount, setNetAmount] = useState(0);
    const [netAmountText, setNetAmountText] = useState('0 ₺');
    const [explanation, setExplanation] = useState('');

    const onChangeCompMpDeliveryTimeTypeDropdown = (data) => {
        if (data === '-1' || data === 'NOW') {
            setDeliveryTime('');
            setDisableDeliveryTime(true);
        } else {
            setDisableDeliveryTime(false);
        }
        setSelectedItemDeliveryTimeType(data);
    }

    const onChangeCompMpCategoryDropdown = (data) => {
        setSelectedItemCategory(data);
    }

    const onChangeCompMpMyCashDeskDropdown = (data) => {
        setSelectedItemMyCashDesk(data);
    }

    const onChangeCompFileUploader = (data) => {
        setProductImageFileId(data);
    }


    function isDecimal(n) {
        if (n === "")
            return false;

        var strCheck = "0123456789.";
        var i;

        for (i in n) {
            if (strCheck.indexOf(n[i]) === -1)
                return false;
        }
        return true;
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'productListPrice') {
            if ((isDecimal(value) === true) || value === '') {
                setProductListPrice(value);
                setNetAmount(value - (value * (commissionRate * 0.01)));
                setNetAmountText(netAmount + ' ₺');
            } else {
                let message = t('Product.Common.Label.Validation.Notification.IsNotDecimal');
            }
        } else if (name === 'deliveryTime') {
            if ((isDecimal(value) === true) || value === '') {
                setDeliveryTime(value);
            } else {
                let message = t('Product.Common.Label.Validation.Notification.IsNotDecimal');
            }
        }
    };


    const callPopUp = () => {

    }

    const callGetBpmTaskService = React.useCallback(() => {
        if (taskId !== null) {
            if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
                let currentSession = sessionStorage.getItem("currentSession");
                axios.defaults.headers.common["X-User-Language"] = 'TR';
                axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
                let ApiGateway = process.env.REACT_APP_API_URL;
                axios.get(ApiGateway + '/bpm/task/user/task/' + taskId)
                    .then(response => {
                        if (response.data?.isSucceed === true) {
                            setDataSave(response.data?.payload);
                            if (response.data?.payload?.genericData !== undefined && response.data?.payload?.genericData !== null) {
                                setProcessId(response.data?.payload?.processInstance?.id);
                                setSelectedItemMyCashDesk(response.data?.payload?.genericData?.cashDeskId);
                                setSelectedItemCategory(response.data?.payload?.genericData?.categoryId);
                                setProductName(response.data?.payload?.genericData?.productName);
                                setDeliveryTime(response.data?.payload?.genericData?.deliveryTime);
                                setSelectedItemDeliveryTimeType(response.data?.payload?.genericData?.deliveryTimeType);
                                setDisableDeliveryTime(dataLocked);
                                setProductImageFileId(response.data?.payload?.genericData?.productImageFileId);
                                setProductListPrice(response.data?.payload?.genericData?.productListPrice);
                                setCommissionRate(response.data?.payload?.genericData?.commissionRate);
                                setCommissionRateText('%' + response.data?.payload?.genericData?.commissionRate);
                                setNetAmount(response.data?.payload?.genericData?.netAmount);
                                setNetAmountText(response.data?.payload?.genericData?.netAmount + ' ₺');
                                setExplanation(response.data?.payload?.genericData?.explanation);
                            }
                            setApiCalled(false);
                        } else {
                            setApiCalled(false);
                            response.data?.messages?.forEach(
                                item => toast.error(item.message, { autoClose: 5000 })
                            );
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [taskId, dataLocked]);

    const callSaveService = React.useCallback((apiRequestBody) => {
        if (taskId !== null) {
            if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
                let currentSession = sessionStorage.getItem("currentSession");
                axios.defaults.headers.common["X-User-Language"] = 'TR';
                axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
                let ApiGateway = process.env.REACT_APP_API_URL;
                axios.post(ApiGateway + '/market-place/bpd/create-bazaar-item/' + taskId + '/save-task', apiRequestBody)
                    .then(response => {
                        if (response.data?.isSucceed === true) {
                            setDataSave(response.data?.payload);
                            callGetBpmTaskService();
                            setApiCalled(false);
                            let message = t('Api.Default.Service.Response.Succeded');
                            toast.success(message, { autoClose: 1000 });
                            setTimeout(() => {
                                let redirectUrl = '/my-cbi-process-list';
                                window.location.href = redirectUrl;
                            }, 200);
                        } else {
                            setApiCalled(false);
                            response.data?.messages?.forEach(
                                item => toast.error(item.message, { autoClose: 5000 })
                            );
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [t, taskId, callGetBpmTaskService]);

    const callSendToApprovalService = React.useCallback((apiRequestBody) => {
        if (taskId !== null) {
            if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
                let currentSession = sessionStorage.getItem("currentSession");
                axios.defaults.headers.common["X-User-Language"] = 'TR';
                axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
                let ApiGateway = process.env.REACT_APP_API_URL;
                axios.post(ApiGateway + '/market-place/bpd/create-bazaar-item/' + taskId + '/send-to-approval', apiRequestBody)
                    .then(response => {
                        if (response.data?.isSucceed === true) {
                            setDataSendToApproval(response.data?.payload);
                            setApiCalled(false);
                            let message = t('Api.Default.Service.Response.Succeded');
                            toast.success(message, { autoClose: 1000 });
                            setTimeout(() => {
                                let redirectUrl = '/my-cbi-process-list';
                                window.location.href = redirectUrl;
                            }, 200);
                        } else {
                            setApiCalled(false);
                            response.data?.messages?.forEach(
                                item => toast.error(item.message, { autoClose: 5000 })
                            );
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [t, taskId]);

    const callReturnService = React.useCallback((apiRequestBody) => {
        if (taskId !== null) {
            if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
                let currentSession = sessionStorage.getItem("currentSession");
                axios.defaults.headers.common["X-User-Language"] = 'TR';
                axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
                let ApiGateway = process.env.REACT_APP_API_URL;
                axios.post(ApiGateway + '/market-place/bpd/create-bazaar-item/' + taskId + '/return', apiRequestBody)
                    .then(response => {
                        if (response.data?.isSucceed === true) {
                            setDataReturn(response.data?.payload);
                            setApiCalled(false);
                            let message = t('Api.Default.Service.Response.Succeded');
                            toast.success(message, { autoClose: 1000 });
                            setTimeout(() => {
                                let redirectUrl = '/my-cbi-process-list';
                                window.location.href = redirectUrl;
                            }, 200);
                        } else {
                            setApiCalled(false);
                            response.data?.messages?.forEach(
                                item => toast.error(item.message, { autoClose: 5000 })
                            );
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [t, taskId]);

    const callApprovalService = React.useCallback((apiRequestBody) => {
        if (taskId !== null) {
            if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
                let currentSession = sessionStorage.getItem("currentSession");
                axios.defaults.headers.common["X-User-Language"] = 'TR';
                axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
                let ApiGateway = process.env.REACT_APP_API_URL;
                axios.post(ApiGateway + '/market-place/bpd/create-bazaar-item/' + taskId + '/approval', apiRequestBody)
                    .then(response => {
                        if (response.data?.isSucceed === true) {
                            setDataApproval(response.data?.payload);
                            setApiCalled(false);
                            let message = t('Api.Default.Service.Response.Succeded');
                            toast.success(message, { autoClose: 1000 });
                            setTimeout(() => {
                                let redirectUrl = '/my-cbi-process-list';
                                window.location.href = redirectUrl;
                            }, 200);
                        } else {
                            setApiCalled(false);
                            response.data?.messages?.forEach(
                                item => toast.error(item.message, { autoClose: 5000 })
                            );
                        }

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [t, taskId]);


    const callSave = () => {
        let apiRequestBody = ({
            cashDeskId: selectedItemMyCashDesk === '-1' ? null : selectedItemMyCashDesk,
            categoryId: selectedItemCategory === '-1' ? null : selectedItemCategory,
            productName: productName,
            deliveryTime: deliveryTime,
            deliveryTimeType: selectedItemDeliveryTimeType === '-1' ? null : selectedItemDeliveryTimeType,
            productImageFileId: productImageFileId,
            productListPrice: productListPrice,
            commissionRate: commissionRate,
            netAmount: netAmount,
            explanation: explanation
        });
        callSaveService(apiRequestBody);
        setApiCalledBy('Save');
        setApiCalled(true);
        setSeed(Math.random());
    }

    const callSendToApproval = () => {
        let apiRequestBody = ({
            cashDeskId: selectedItemMyCashDesk === '-1' ? null : selectedItemMyCashDesk,
            categoryId: selectedItemCategory === '-1' ? null : selectedItemCategory,
            productName: productName,
            deliveryTime: deliveryTime,
            deliveryTimeType: selectedItemDeliveryTimeType === '-1' ? null : selectedItemDeliveryTimeType,
            productImageFileId: productImageFileId,
            productListPrice: productListPrice,
            commissionRate: commissionRate,
            netAmount: netAmount,
            explanation: explanation
        });
        callSendToApprovalService(apiRequestBody);
        setApiCalledBy('SendToApproval');
        setApiCalled(true);
        setSeed(Math.random());
    }

    const callReturn = () => {
        let apiRequestBody = ({
            cashDeskId: selectedItemMyCashDesk === '-1' ? null : selectedItemMyCashDesk,
            categoryId: selectedItemCategory === '-1' ? null : selectedItemCategory,
            productName: productName,
            deliveryTime: deliveryTime,
            deliveryTimeType: selectedItemDeliveryTimeType === '-1' ? null : selectedItemDeliveryTimeType,
            productImageFileId: productImageFileId,
            productListPrice: productListPrice,
            commissionRate: commissionRate,
            netAmount: netAmount,
            explanation: explanation
        });
        callReturnService(apiRequestBody);
        setApiCalledBy('Return');
        setApiCalled(true);
        setSeed(Math.random());
    }

    const callApproval = () => {
        let apiRequestBody = ({
            cashDeskId: selectedItemMyCashDesk === '-1' ? null : selectedItemMyCashDesk,
            categoryId: selectedItemCategory === '-1' ? null : selectedItemCategory,
            productName: productName,
            deliveryTime: deliveryTime,
            deliveryTimeType: selectedItemDeliveryTimeType === '-1' ? null : selectedItemDeliveryTimeType,
            productImageFileId: productImageFileId,
            productListPrice: productListPrice,
            commissionRate: commissionRate,
            netAmount: netAmount,
            explanation: explanation
        });
        callApprovalService(apiRequestBody);
        setApiCalledBy('Approval');
        setApiCalled(true);
        setSeed(Math.random());
    }

    const callSendAgainToApproval = () => {
        let apiRequestBody = ({
            cashDeskId: selectedItemMyCashDesk === '-1' ? null : selectedItemMyCashDesk,
            categoryId: selectedItemCategory === '-1' ? null : selectedItemCategory,
            productName: productName,
            deliveryTime: deliveryTime,
            deliveryTimeType: selectedItemDeliveryTimeType === '-1' ? null : selectedItemDeliveryTimeType,
            productImageFileId: productImageFileId,
            productListPrice: productListPrice,
            commissionRate: commissionRate,
            netAmount: netAmount,
            explanation: explanation
        });
        callSendToApproval(apiRequestBody);
        setApiCalledBy('SendAgainToApproval');
        setApiCalled(true);
        setSeed(Math.random());
    }

    const callCancel = () => {
        setApiCalledBy('Cancel');
        setApiCalled(true);
        let message = t('Product.Common.Label.Bpm.Notification.Cancel');
        //toast.success(message, { autoClose: 1000 });
        setTimeout(() => {
            let redirectUrl = '/my-cbi-process-list';
            window.location.href = redirectUrl;
        }, 1000);
        setSeed(Math.random());
    }

    useEffect(() => {
        setTaskId(params?.taskId);
        callGetBpmTaskService();
    }, [params, callGetBpmTaskService]);

    useEffect(() => {
        setDisableDeliveryTime(dataLocked);
    }, [dataLocked, deliveryTime]);

    return (
        <StandartPage title={t('Mp.Cbi.MyProcess.List.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <ToastContainer />
                <div className='d-none'><span className='f5'>{t('Henüz satışta ürününüz bulunmuyor. Ürünlerinizi ekleyin Yuhdeve.com ile farkı yaşayın!')}</span></div>

                {(() => {
                    if (componentId === 'CreateCbiProcessApplicationApproval') {
                        return (
                            <>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <div>
                                                    <Button color="dark" onClick={toggle}>
                                                        {t('CrmDetails.Label.CrmLeftBar.Title')}
                                                    </Button>
                                                    <Modal isOpen={modal} toggle={toggle} size="xl" >
                                                        <ModalHeader toggle={toggle}>{t('CrmDetails.Label.CrmLeftBar.Title')}</ModalHeader>
                                                        <ModalBody>
                                                            <CompCrmDetailsTab processId={processId} />
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <Button color="dark" onClick={toggle}>
                                                                {t('Product.Common.Label.Button.Close')}
                                                            </Button>
                                                        </ModalFooter>
                                                    </Modal>
                                                </div>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                })()}

                <Row className={componentId === 'CreateCbiProcessApplicationApproval' ? "mt-2" : ""}>
                    <Col lg={6}>
                        <Card>
                            <CardBody>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.Category')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <CompMpCategoryDropdown key={seed} onChange={onChangeCompMpCategoryDropdown} selectedOption={selectedItemCategory} isDisabled={dataLocked} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.ProductName')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <Input disabled={dataLocked === true ? "disabled" : ""} type="text" className="form-control"
                                                        onChange={(e) => setProductName(e.target.value)} onKeyUp={(e) => setProductName(e.target.value)} value={productName}
                                                        placeholder={t('Bpm.Mp.BazaarItem.Create.PlaceHolder.ProductName')} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.DeliveryTime')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3 mb-lg-0">
                                                <Input disabled={disableDeliveryTime} type="text" className="form-control" name="deliveryTime"
                                                    onChange={handleChange} onKeyUp={handleChange} value={deliveryTime}
                                                    placeholder={t('Bpm.Mp.BazaarItem.Create.PlaceHolder.DeliveryTime')} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <CompMpDeliveryTimeTypeDropdown key={seed} onChange={onChangeCompMpDeliveryTimeTypeDropdown} selectedOption={selectedItemDeliveryTimeType} isDisabled={dataLocked} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.ProductImageFile')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <CompFileUploader onChange={onChangeCompFileUploader} datastore={productImageFileId} isDeleteButtonDisabled={dataLocked} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </CardBody>
                        </Card>

                    </Col>

                    <Col lg={6}>
                        <Card>
                            <CardBody>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.MyCashDesk')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <CompMpMyCashDeskDropdown key={seed} onChange={onChangeCompMpMyCashDeskDropdown} selectedOption={selectedItemMyCashDesk} isDisabled={dataLocked} fixedCashDeskEnabled={dataLocked} fixedCashDeskId={selectedItemMyCashDesk} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.ProductListPrice')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <Input disabled={dataLocked === true ? "disabled" : ""} type="text" className="form-control" name="productListPrice"
                                                        onChange={handleChange} onKeyUp={handleChange} value={productListPrice}
                                                        placeholder={t('Bpm.Mp.BazaarItem.Create.PlaceHolder.ProductListPrice')} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.CommissionRate')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <Input disabled={true} type="text" className="form-control"
                                                        onChange={(e) => setCommissionRateText(e.target.value)} onKeyUp={(e) => setCommissionRateText(e.target.value)} value={commissionRateText}
                                                        placeholder={t('Bpm.Mp.BazaarItem.Create.PlaceHolder.CommissionRate')} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <Label className="form-label">{t('Bpm.Mp.BazaarItem.Create.Label.NetAmount')}</Label>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 mb-lg-0">
                                                <div className="mb-0">
                                                    <Input disabled={true} type="text" className="form-control" name="netAmountText"
                                                        onChange={handleChange} onKeyUp={handleChange} value={netAmountText}
                                                        placeholder={t('Bpm.Mp.BazaarItem.Create.PlaceHolder.NetAmount')} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>


                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row className={((componentId === 'CreateCbiProcessApplicationApproval' === true) || (componentId === 'CreateCbiProcessApplicationReturn' === true)) ? "mt-2" : "d-none"}>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="mb-3">
                                    <Label className="form-label">{t('Product.Common.Label.Bpm.DecisionTable')}</Label>
                                    <CompDecisionDataTable key={seed} taskId={taskId} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="mb-3">
                                    <Label className="form-label">{t('Bpm.Erp.Invoice.PurchaseInvoiceCreate.Label.Explanation')}</Label>
                                    <CKEditor
                                        config={{
                                            toolbar: ['bold', 'italic', 'bulletedList', 'numberedList'],
                                            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed', 'link'],
                                        }}
                                        editor={ClassicEditor}
                                        data={explanation}
                                        onReady={(editor) => {
                                            editor.ui.view.editable.element.style.minHeight = '100px';
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setExplanation(data);
                                        }}
                                    />
                                </div>

                                <div className="mb-3">
                                    {(() => {
                                        if (componentId === 'CreateCbiProcessApplication') {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className=" mb-4">
                                                                <button disabled={apiCalled} type="submit" className="d-none btn btn-primary w-sm" onClick={(e) => callCancel()}>{t('Product.Common.Label.Bpm.Button.Cancel')}</button>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="text-end mb-4">
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callSave()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'Save' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'Save' ? t('Product.Common.Label.Bpm.Button.Save') : ''}
                                                                </Button>
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callSendToApproval()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'SendToApproval' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'SendToApproval' ? t('Product.Common.Label.Bpm.Button.SendToApproval') : ''}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        } else if (componentId === 'CreateCbiProcessApplicationApproval') {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className=" mb-4">
                                                                <button disabled={apiCalled} type="submit" className="d-none btn btn-primary w-sm" onClick={(e) => callCancel()}>{t('Product.Common.Label.Bpm.Button.Cancel')}</button>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="text-end mb-4">
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callSave()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'Save' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'Save' ? t('Product.Common.Label.Bpm.Button.Save') : ''}
                                                                </Button>
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callReturn()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'Return' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'Return' ? t('Product.Common.Label.Bpm.Button.Return') : ''}
                                                                </Button>
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callApproval()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'Approval' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'Approval' ? t('Product.Common.Label.Bpm.Button.Approval') : ''}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>

                                            )
                                        } else if (componentId === 'CreateCbiProcessApplicationReturn') {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className=" mb-4">
                                                                <button disabled={apiCalled} type="submit" className="d-none btn btn-primary w-sm" onClick={(e) => callCancel()}>{t('Product.Common.Label.Bpm.Button.Cancel')}</button>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="text-end mb-4">
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callSave()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'Save' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'Save' ? t('Product.Common.Label.Bpm.Button.Save') : ''}
                                                                </Button>
                                                                <Button disabled={apiCalled} color="primary" className="btn-load w-sm me-1" outline onClick={(e) => callSendAgainToApproval()}>
                                                                    <span className={apiCalled === true && apiCalledBy === 'SendAgainToApproval' ? "d-flex align-items-center" : "d-none"}>
                                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">
                                                                            {t('Product.Common.Label.Bpm.Button.PleaseWait')}
                                                                        </span>
                                                                    </span>
                                                                    {apiCalled === false || apiCalledBy !== 'SendAgainToApproval' ? t('Product.Common.Label.Bpm.Button.SendAgainToApproval') : ''}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>

                                            )
                                        }
                                    })()}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}