import React, { useState, useEffect } from 'react';
import StandartPage from '../../Layouts/Shared/_StandartPage';
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Row, Col, Label, Input, Spinner, Button } from 'reactstrap';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import 'ckeditor5/ckeditor5.css';
import defaultProfile2 from '../../Assets/Images/profile/defaultProfile2.jpg';
import CompCrmSegmentDropdown from '../../Components/Common/CompCrmSegmentDropdown';
import CompCrmCountryDropdown from '../../Components/Common/CompCrmCountryDropdown';
import CompCrmCityDropdown from '../../Components/Common/CompCrmCityDropdown';
import CompCrmDistrictDropdown from '../../Components/Common/CompCrmDistrictDropdown';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
export default function CompCrmInvoiceDetails() {

    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [displayName, setDisplayName] = useState(false);
    const [seed, setSeed] = useState(0);
    const [logged, setLogged] = useState(false);
    const [dataMyCrmDetails, setDataMyCrmDetails] = useState(null);
    const [dataMyCrmBankAccountsDetails, setDataMyCrmBankAccountsDetails] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            setDisplayName(JSON.parse(currentSession)?.displayName);
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/trader/my-crm-details')
                .then(response => {
                    setDataMyCrmDetails(response.data?.payload);
                    setLogged(true);
                    /*axios.get(ApiGateway + '/market-place/trader/my-cash-desk/list')
                        .then(thenResponse => {
                            setDataMyCrmBankAccountsDetails(thenResponse.data?.payload);
                        })
                        .catch(thenError => {
                            console.error(thenError);
                        });
                    */
                })
                .catch(error => {
                    console.error(error);
                });
        }
        setTabIndex(0);
    }, [t]);

    const [selectedItemSegment, setSelectedItemSegment] = useState('-1');
    const [segmentPersonalBlock, setSegmentPersonalBlock] = useState('d-none');
    const [segmentCompanyBlock, setSegmentCompanyBlock] = useState('d-none');
    const [formRemainIsShow, setFormRemainIsShow] = useState('d-none');
    const [title, setTitle] = useState('');

    const [contactName, setContactName] = useState('');
    const [contactSurname, setContactSurname] = useState('');
    const [contactIdentityNumber, setContactIdentityNumber] = useState('');

    const [legalCompanyTitle, setLegalCompanyTitle] = useState('');
    const [taxOffice, setTaxOffice] = useState('');
    const [taxNumber, setTaxNumber] = useState('');

    const [note, setNote] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber,] = useState('');
    const [gsmNumber, setGsmNumber,] = useState('');
    const [selectedItemCountry, setSelectedItemCountry] = useState('-1');
    const [selectedItemCity, setSelectedItemCity] = useState('-1');
    const [selectedItemDistrict, setSelectedItemDistrict] = useState('-1');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');

    const onChangeCompCrmSegmentDropdown = (data) => {
        if (data?.includes("RETAIL_")) {
            setSegmentCompanyBlock('d-none');
            setSegmentPersonalBlock('mb-3');
            setFormRemainIsShow('mb-3');
        } else if (data?.includes("COMMERCIAL_")) {
            setSegmentPersonalBlock('d-none');
            setSegmentCompanyBlock('mb-3');
            setFormRemainIsShow('mb-3');
        } else {
            setSegmentCompanyBlock('d-none');
            setSegmentPersonalBlock('d-none');
            setFormRemainIsShow('d-none');
        }
        setSelectedItemSegment(data);
    }

    const onChangeCompCrmCountryDropdown = (data) => {
        setSelectedItemCountry(data);
        setSelectedItemCity(-1);
        setSelectedItemDistrict(-1);
    }

    const onChangeCompCrmCityDropdown = (data) => {
        setSelectedItemCity(data);
        setSelectedItemDistrict(-1);
    }

    const onChangeCompCrmDistrictDropdown = (data) => {
        setSelectedItemDistrict(data);
    }

    useEffect(() => {
        if (dataMyCrmDetails !== undefined && dataMyCrmDetails !== null) {
            let message = t('Product.Common.Label.Data.Notification.Loaded');
            onChangeCompCrmSegmentDropdown(dataMyCrmDetails?.segment?.value);
            setContactName(dataMyCrmDetails?.contactName);
            setContactSurname(dataMyCrmDetails?.contactSurname);
            setContactIdentityNumber(dataMyCrmDetails?.contactIdentityNumber);
            setLegalCompanyTitle(dataMyCrmDetails?.legalCompanyTitle);
            setTaxOffice(dataMyCrmDetails?.taxOffice);
            setTaxNumber(dataMyCrmDetails?.taxNumber);
            setTitle(dataMyCrmDetails?.title);
            setSelectedItemCountry(dataMyCrmDetails?.district?.city?.country?.id);
            setSelectedItemCity(dataMyCrmDetails?.district?.city?.id);
            setSelectedItemDistrict(dataMyCrmDetails?.district?.id);
            setAddress(dataMyCrmDetails?.address);
            setZipCode(dataMyCrmDetails?.zipCode);
            setPhoneNumber(dataMyCrmDetails?.phoneNumber);
            setGsmNumber(dataMyCrmDetails?.gsmNumber);
            setEmail(dataMyCrmDetails?.email);
            if (dataMyCrmDetails?.note !== undefined && dataMyCrmDetails?.note !== null) {
                setNote(dataMyCrmDetails?.note);
            }
        }
    }, [t, logged, dataMyCrmDetails]);


    const callPopUp = () => {

    }


    return (
        <React.Fragment>
            <ToastContainer />
            {dataMyCrmDetails ? ((
                <Row>
                    <Col className={"col-md-12"}>
                        <Row className="mt-3 mb-3">
                            <Col className={"col-md-6"}>
                                <Label className="form-label">{t('CrmCreate.Label.Segment')}</Label>
                                <CompCrmSegmentDropdown seedValue={seed} onChange={onChangeCompCrmSegmentDropdown} selectedOption={selectedItemSegment} isDisabled={true} />
                            </Col>
                        </Row>
                        <Row className={segmentPersonalBlock}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.ContactName')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setContactName(e.target.value)} onKeyUp={(e) => setContactName(e.target.value)} value={contactName} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.ContactName')} />
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.ContactSurname')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setContactSurname(e.target.value)} onKeyUp={(e) => setContactSurname(e.target.value)} value={contactSurname} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.ContactSurname')} />
                            </Col>
                        </Row>
                        <Row className={segmentPersonalBlock}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.ContactIdentityNumber')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setContactIdentityNumber(e.target.value)} onKeyUp={(e) => setContactIdentityNumber(e.target.value)} value={contactIdentityNumber} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.ContactIdentityNumber')} />
                            </Col>
                        </Row>
                        <Row className={segmentCompanyBlock}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.LegalCompanyTitle')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setLegalCompanyTitle(e.target.value)} onKeyUp={(e) => setLegalCompanyTitle(e.target.value)} value={legalCompanyTitle} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.LegalCompanyTitle')} />
                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row>
                        <Row className={segmentCompanyBlock}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.TaxOffice')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setTaxOffice(e.target.value)} onKeyUp={(e) => setTaxOffice(e.target.value)} value={taxOffice} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.TaxOffice')} />
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.TaxNumber')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setTaxNumber(e.target.value)} onKeyUp={(e) => setTaxNumber(e.target.value)} value={taxNumber} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.TaxNumber')} />
                            </Col>
                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.Country')}</Label>
                                <CompCrmCountryDropdown key={seed} onChange={onChangeCompCrmCountryDropdown} selectedOption={selectedItemCountry} isDisabled={true} />
                            </Col>
                            <Col lg={6}>
                                <Label htmlFor="choices-status-input" className="form-label">{t('CrmCreate.Label.City')}</Label>
                                <CompCrmCityDropdown key={seed} onChange={onChangeCompCrmCityDropdown} cascadeOption={selectedItemCountry} selectedOption={selectedItemCity} isDisabled={true} />
                            </Col>
                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={6}>
                                <Label htmlFor="datepicker-deadline-input" className="form-label">{t('CrmCreate.Label.District')}</Label>
                                <CompCrmDistrictDropdown key={seed} onChange={onChangeCompCrmDistrictDropdown} cascadeOption={selectedItemCity} selectedOption={selectedItemDistrict} isDisabled={true} />
                            </Col>
                            <Col lg={6}>
                                <Label htmlFor="datepicker-deadline-input" className="form-label">{t('CrmCreate.Label.ZipCode')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setZipCode(e.target.value)} onKeyUp={(e) => setZipCode(e.target.value)} defaultValue={zipCode} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.ZipCode')} />
                            </Col>

                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={12}>
                                <Label className="form-label">{t('CrmCreate.Label.Address')}</Label>
                                <Input type="textarea" className="form-control" rows={1}
                                    onChange={(e) => setAddress(e.target.value)} onKeyUp={(e) => setAddress(e.target.value)} defaultValue={address} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.Address')} />
                            </Col>
                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.PhoneNumber')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setPhoneNumber(e.target.value)} onKeyUp={(e) => setPhoneNumber(e.target.value)} defaultValue={phoneNumber} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.PhoneNumber')} />
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label">{t('CrmCreate.Label.GsmNumber')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setGsmNumber(e.target.value)} onKeyUp={(e) => setGsmNumber(e.target.value)} defaultValue={gsmNumber} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.GsmNumber')} />
                            </Col>
                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={6}>
                                <Label htmlFor="choices-status-input" className="form-label">{t('CrmCreate.Label.Email')}</Label>
                                <Input type="text" className="form-control"
                                    onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => setEmail(e.target.value)} defaultValue={email} disabled={true}
                                    placeholder={t('CrmCreate.PlaceHolder.Email')} />
                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row>
                        <Row className={formRemainIsShow}>
                            <Col lg={6}>

                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            )) : (
                <Row>
                    <Col className={"col-md-12 text-center"}>
                        <Spinner
                            className="m-5"
                            color="primary"
                        >
                            Loading...
                        </Spinner>
                    </Col>
                </Row>
            )}

        </React.Fragment>
    )

}